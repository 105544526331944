<template>
  <div class="m-4">
    <h3 class="m-1">Resume Keperawatan Pasien Pulang Rawat Inap</h3>
    <v-row class="mt-1">
      <v-col cols="12" lg="8" md="8" sm="10">
        <v-card color="teal darken-1" dark>
          <v-card-text>
            <v-row class="mt-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title>Resume Keperawatn Pasien Pulang</v-card-title>

          <v-card-text>
            <v-row class="mt-0 mb-0 m-1">
              <v-col
                v-for="(v, i) in master.form1"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[i] = e)"
                  vclass="c-text-field"
                  :value="data.form1[i]"
                  :data="v.data"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3 mb-3" />
            <v-row class="mt-0 mb-0">
              <v-col
                v-for="(v, i) in master.form2"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form2[i] = e)"
                  vclass="c-text-field"
                  :data="v.data"
                  :value="data.form2[i]"
                  :property="v.property"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="4" sm="4">
        <v-card>
          <v-card-title>History Resume Medis</v-card-title>
          <v-card-text>
            <div
              class="list-group mt-1"
              v-for="(itemresume, index) of listData"
              :key="index"
            >
              <div
                class="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div class="d-flex w-100 justify-content-between">
                  <p class="mb-1">
                    <b color="primary-text">Dokter DPJP&nbsp;:&nbsp;</b
                    >{{ itemresume.dokterUtama }}
                  </p>
                  <small>{{ itemresume.created_at }}</small>
                </div>
                <p class="mb-1">
                  <v-btn
                    color="orange darken-2"
                    @click="cetakResumeMedis(itemresume)"
                    elevation-2
                    dark
                    medium
                    class="btn-block mt-1"
                    >Cetak Resume &nbsp;<v-icon small
                      >mdi-printer</v-icon
                    ></v-btn
                  >
                </p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn
          @click="postSave"
          :disabled="disabled"
          color="primary"
          medium
          class="btn-block"
        >
          Simpan Resume
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/resume_keperawatan_pasien_pulang_rawat_inap'
import Dialog from '@/components/Dialog'
// import { apiKedua } from '../../plugins/supports'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading,
  baseUrlEmr
} from '../../plugins/supports'
export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data () {
    return {
      disabled: false,
      procedurs: [
        {
          previous: '',
          expiration: ''
        }
      ],
      master: Master,
      listData: [],
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form1: {
          suhu: '',
          nadi: '',
          rr: '',
          td: '',
          diet_nutrisi: '',
          bab: '',
          bak: '',
          luka_luka_operasi: '',
          transfer_mobilisasi: '',
          alat_bantu: '',
          kontraksi_uterus: '',
          vulva: '',
          lochea: ''
        },
        form2: {
          edukasi: '',
          edukasi2: '',
          edukasi3: '',
          diagnosa_keperawatan_selama_dirawat: '',
          diagnosa_keperawatan_selama_dirawat2: '',
          anjuran_keperawatan_khusus_setelah_pulang: '',
          anjuran_keperawatan_khusus_setelah_pulang2: '',
          anjuran_keperawatan_khusus_setelah_pulang3: '',
          anjuran_keperawatan_khusus_setelah_pulang4: '',
          manajemen_nyeri: '',
          perkembangan_selama_dirawat: '',
          prognose: '',
          barang_hasil_hasil_laboratorium: '',
          barang_hasil_surat_asuransi: '',
          barang_hasil_foto_rontgen: '',
          barang_hasil_resume_pasien_pulang: '',
          ct_scan: '',
          buku_bayi: '',
          mri_mra: '',
          kartu_gol_darah_bayi: '',
          hasil_usg: '',
          skl: '',
          surat_keterangan_sakit: '',
          bayi_diserahkan_oleh: '',
          lain_lain: '',
          instruksi_tindak_lanjut: '',
          obat_yang_dibawa_pulang: '',
          tanggal: '',
          hari: '',
          jam: '',
          poliklinik_yang_dituju: '',
          bagian: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created () {
    if (!this.$route.query.no_rm) {
      errorMsg('PILIH DAHULU PASIENNYA')
      this.$router.push({
        name: 'DataPasienRawatInap'
      })
    }
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.getAssemen(this.$route.query.folio_id)
      this.getListResumeMedis()
    }
  },
  methods: {
    postSave () {
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        data: this.data,
        type: 'Resume Keperawatan Pasien Pulang'
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-resume-rawat-inap',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.getListResumeMedis()
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    addPro () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    getAssemen (folioId) {
      var type = 'Resume Keperawatan Pasien Pulang'
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-resume-rawat-inap',
        { folio_id: folioId, jenis: type },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data = results
            // this.data.form6 = results.form6
            // this.data.demografi.jenis_kelamin = {
            //   value: results.jenis_kelamin,
            //   text: results.jenis_kelamin
            // }
          } else {
          }
        },
        'JSON'
      )
    },
    getListResumeMedis () {
      const norm = this.$route.query.no_rm
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-resume-rawat-inap-list',
        {
          rm: norm,
          jenis: 'Resume Keperawatan Pasien Pulang'
        },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            const listData = []
            for (const item of results) {
              // idcArray.push(item.text)
              listData.push({
                id_resume_rawat_inap: item.id_resume_rawat_inap,
                dokterUtama: item.dokterUtama,
                created_at: item.created_at
              })

              console.log(item)
            }
            this.listData = listData
          }
        }
      )
    },
    cetakResumeMedis (VALUE) {
      window
        .open(
          baseUrlEmr +
            `laporan-medis/cetak-resume-medis-keperawatan?params=${window.btoa(
              VALUE.id_resume_rawat_inap
            )}`,
          '_blank'
        )
        .focus()
    }
  },
  watch: {
    'data.form2.poliklinik_yang_dituju' (a, b) {
      // console.log(a)

      const subUnitId = a.id
      const tanggal = this.data.form2.tanggal
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-jadwal-dokter',
        { sub_unit: subUnitId, tanggal: tanggal },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.disabled = false
          } else {
            errorMsg(msg)
            this.disabled = true
          }
        },
        'JSON'
      )
    },
    'data.form2.tanggal' (a, b) {
      // console.log(a)

      const subUnitId = this.data.form2.poliklinik_yang_dituju.id
      const tanggal = this.data.form2.tanggal
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-jadwal-dokter',
        { sub_unit: subUnitId, tanggal: tanggal },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.disabled = false
          } else {
            errorMsg(msg)
            this.disabled = true
          }
        },
        'JSON'
      )
    }
  }
}
</script>

<style>
.float-group {
  position: fixed;
  bottom: 10%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
