export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      disabled: true,

      col: 3
    },
    ruangan: {
      widget: 'wtext',
      data: null,
      label: 'Ruangan',
      disabled: true,

      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      disabled: true,

      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Utama',
      disabled: true,

      col: 6
    },
    dpjp_pembantu: {
      widget: 'wtext',
      data: null,
      label: 'Dokter DPJP Kedua',
      disabled: true,

      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      disabled: true,
      label: 'No Reg',
      col: 6
    }
  },
  form1: {
    label1: {
      label: 'Diisi Oleh Perawat/bidan :',
      widget: 'wlabelkiri',
      data: [],
      col: 4
    },
    suhu: {
      label: ' ',
      widget: 'wtext',
      col: 8,
      data: [],
      property: { attrs: { prefix: 'Suhu', suffix: '°C', type: 'string' } }
    },
    nadi: {
      label: ' ',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { prefix: 'Nadi', suffix: 'x/mt', type: 'string' } }
    },
    rr: {
      label: ' ',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { prefix: 'RR', suffix: 'x/mt', type: 'string' } }
    },
    td: {
      label: ' ',
      widget: 'wtext',
      col: 12,
      data: [],
      property: { attrs: { prefix: 'TD', suffix: 'mmHg', type: 'string' } }
    },
    label2: {
      label: 'Diet / Nutrisi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    diet_nutrisi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Oral', value: 'Oral' },
        { text: 'NGT', value: 'NGT' },
        { text: 'Diet Khusus, Jelaskan', value: null, field: 'auto' },
        { text: 'Batasan Cairan', value: null, field: 'auto' }
      ]
    },
    label3: {
      label: 'BAB',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    bab: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Ileostomy/colostomy', value: 'Ileostomy/colostomy' },
        { text: 'Inkontenesia urine', value: 'Inkontenesia urine' },
        { text: 'Inkontenesia alvi', value: 'Inkontenesia alvi' }
      ]
    },
    label4: {
      label: 'BAK',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    bak: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Normal', value: 'Normal' },
        { text: 'Kateter, Tanggal pemasangan terakhir', value: null, field: 'auto' }
      ]
    },
    label5: {
      label: 'Luka-Luka Operasi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    luka_luka_operasi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Bersih', value: 'Normal' },
        { text: 'Kering', value: 'Normal' },
        { text: 'Ada cairan luka, jelaskan', value: null, field: 'auto' }
      ]
    },
    label6: {
      label: 'Transfer / Mobilisasi',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    transfer_mobilisasi: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Mandiri bantu sebagian', value: 'Mandiri bantu sebagian' },
        { text: 'Dibantu penuh', value: 'Dibantu penuh' }
      ]
    },
    label7: {
      label: 'Alat Bantu',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    alat_bantu: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tongkat', value: 'Tongkat' },
        { text: 'Kursi Roda', value: 'Kursi Roda' },
        { text: 'Trolley / kereta dorong', value: 'Trolley / kereta dorong' },
        { text: 'Lain-lain', value: 'Lain-lain' }
      ]
    },
    label8: {
      label: 'Diisi Oleh Bidan (Khusus pasien Kebidanan)',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label9: {
      label: 'Kontraksi Uterus',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    kontraksi_uterus: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Tidak', value: 'Tidak' },
        { text: 'Baik', value: 'Baik' },
        { text: 'Tinggi fundus uteri :', value: null, field: 'auto' }
      ]
    },
    label10: {
      label: 'Vulva',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    vulva: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Bersih', value: 'Bersih' },
        { text: 'Kotor', value: 'Kotor' },
        { text: 'Bengkak', value: 'Bengkak' },
        { text: 'Luka Perineum', value: 'Luka Perineum' },
        { text: 'Kering', value: 'Kering' },
        { text: 'Basah', value: 'Basah' }
      ]
    },
    label11: {
      label: 'Lochea',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    lochea: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Banyak', value: 'Banyak' },
        { text: 'Sedikit', value: 'Sedikit' },
        { text: 'Warna', value: 'Warna' },
        { text: 'Bau', value: 'Bau' }
      ]
    }
  },
  form2: {
    label1: {
      label: 'EDUKASI / PENYULUHAN KESEHATAN YANG SUDAH DIBERIKAN :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    edukasi: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Penyakit dan pengobatanya', value: 'Penyakit dan pengobatanya' },
        { text: 'Mengatasi nyeri', value: 'Mengatasi nyeri' },
        { text: 'Persiapan lingkungan dan fasilitas untuk perawatan di rumah', value: 'Persiapan lingkungan dan fasilitas untuk perawatan di rumah' }
      ]
    },
    edukasi2: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Perawatan dirumah', value: 'Perawatan dirumah' },
        { text: 'Perawatan luka', value: 'Perawatan luka' }
      ]
    },
    edukasi3: {
      label: ' ',
      widget: 'wcheckbox',
      col: 4,
      data: [
        { text: 'Perawatan ibu dan bayi', value: 'Perawatan ibu dan bayi' },
        { text: 'Nasehat Keluarga Berencana', value: 'Nasehat Keluarga Berencana' }
      ]
    },
    label2: {
      label: 'DIAGNOSA KEPERAWATAN SELAMA DIRAWAT :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    diagnosa_keperawatan_selama_dirawat: {
      label: ' ',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { prefix: '1', type: 'text' } }
    },
    diagnosa_keperawatan_selama_dirawat2: {
      label: ' ',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { prefix: '2', type: 'text' } }
    },
    label3: {
      label: 'ANJURAN KEPERAWATAN KHUSUS SETELAH PULANG :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    anjuran_keperawatan_khusus_setelah_pulang: {
      label: ' ',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { prefix: '1', type: 'text' } }
    },
    anjuran_keperawatan_khusus_setelah_pulang2: {
      label: ' ',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { prefix: '2', type: 'text' } }
    },
    anjuran_keperawatan_khusus_setelah_pulang3: {
      label: ' ',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { prefix: '3', type: 'text' } }
    },
    anjuran_keperawatan_khusus_setelah_pulang4: {
      label: ' ',
      widget: 'wtext',
      col: 6,
      data: [],
      property: { attrs: { prefix: '4', type: 'text' } }
    },
    label4: {
      label: 'MANAJEMEN NYERI :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label4a: {
      label: 'Terapi Nyeri :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    manajemen_nyeri: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Edukasi', value: 'Edukasi' },
        { text: 'Nsaid', value: 'Nsaid' },
        { text: 'Narkotik', value: 'Narkotik' },
        { text: 'Tindakan Intervensi Nyeri', value: 'Tindakan Intervensi Nyeri' }
      ]
    },
    label5: {
      label: 'Perkembangan selama dirawat :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    perkembangan_selama_dirawat: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Kurang berkembang', value: 'Kurang berkembang' },
        { text: 'Tidak ada perkembangan', value: 'Tidak ada perkembangan' }
      ]
    },
    label6: {
      label: 'Komplikasi :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label7: {
      label: 'Prognose :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    prognose: {
      label: ' ',
      widget: 'wradio',
      col: 10,
      data: [
        { text: 'Baik', value: 'Baik' },
        { text: 'Kurang Baik', value: 'Kurang Baik' },
        { text: 'Tidak Baik', value: 'Tidak Baik' }
      ]
    },
    label8: {
      label: 'Barang dan hasil pemeriksaan yang diserahkan pada pasien / keluarga :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    label8a: {
      label: '1. Hasil Laboratorium',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    barang_hasil_hasil_laboratorium: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: [],
      property: { attrs: { suffix: 'lembar', type: 'text' } }
    },
    label8b: {
      label: '5. Surat Asuransi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    barang_hasil_surat_asuransi: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label8c: {
      label: '2. Foto Rontgen',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    barang_hasil_foto_rontgen: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: [],
      property: { attrs: { suffix: 'lembar', type: 'text' } }
    },
    label8d: {
      label: '6. Resume pasien Pulang :',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    barang_hasil_resume_pasien_pulang: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label8e: {
      label: ' CT. Scan',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    ct_scan: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: [],
      property: { attrs: { suffix: 'lembar', type: 'text' } }
    },
    label8f: {
      label: ' 5. Buku Bayi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    buku_bayi: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label8g: {
      label: ' MRI/MRA',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    mri_mra: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: [],
      property: { attrs: { suffix: 'lembar', type: 'text' } }
    },
    label8h: {
      label: ' 6. Kartu Gol. Darah Bayi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    kartu_gol_darah_bayi: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label8i: {
      label: '3. Hasil USG',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    hasil_usg: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: [],
      property: { attrs: { suffix: 'lembar', type: 'text' } }
    },
    label8j: {
      label: ' 5. Surat Keterangan Lahir (SKL)',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    skl: {
      label: ' ',
      widget: 'wradio',
      col: 3,
      data: [
        { text: 'Ada', value: 'Ada' },
        { text: 'Tidak', value: 'Tidak' }
      ]
    },
    label8k: {
      label: '4. Surat Keterangan Sakit',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    surat_keterangan_sakit: {
      label: ' ',
      widget: 'wtext',
      col: 4,
      data: [],
      property: { attrs: { suffix: 'lembar', type: 'text' } }
    },
    label8l: {
      label: ' 6. Bayi diserahkan oleh',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    bayi_diserahkan_oleh: {
      label: ' ',
      widget: 'wtext',
      col: 3,
      data: []
    },
    label8m: {
      label: 'Lain-lain :',
      widget: 'wlabelkiri',
      data: [],
      col: 2
    },
    lain_lain: {
      label: ' ',
      widget: 'wtext',
      col: 10,
      data: []
    },
    label9: {
      label: 'Instruksi / Tindak Lanjut',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    instruksi_tindak_lanjut: {
      label: ' ',
      widget: 'wradio',
      col: 12,
      data: [
        { text: 'Kontrol kembali ke sesuai jadwal', value: 'Kontrol kembali ke sesuai jadwal' },
        { text: 'Kalau ada perubahan kondisi tidak baik segera kembali ke IGD RS Syafira', value: 'Kalau ada perubahan kondisi tidak baik segera kembali ke IGD RS Syafira' }
      ]
    },
    label10: {
      label: 'Obat yang dibawa pulang :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    obat_yang_dibawa_pulang: {
      label: ' ',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    label11: {
      label: 'Rencana Kontrol Selanjutnya di RS. Syafira / RS :',
      widget: 'wlabelkiri',
      data: [],
      col: 12
    },
    tanggal: {
      label: 'Tanggal',
      widget: 'wdatenormal',
      col: 4,
      data: []
    },
    // hari: {
    //   label: 'Hari',
    //   widget: 'wtext',
    //   col: 4,
    //   data: []
    // },
    // jam: {
    //   label: 'Jam',
    //   widget: 'wtext',
    //   col: 4,
    //   data: []
    // },
    poliklinik_yang_dituju: {
      label: 'Poliklinik Yang Dituju',
      widget: 'wautocomplatesingle',
      col: 8,
      data: ''
    }
    // bagian: {
    //   label: 'Bagian',
    //   widget: 'wtext',
    //   col: 4,
    //   data: []
    // }
  }
}
